<template>
    <modal ref="modalGaleriaVista" titulo="Editar la galería de imagenes de la vista" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0 px-5">
            <div class="btn-general px-4 f-14" @click="toggle();$emit('subirImagen')">
                Agregar Imagen
            </div>
        </div>
        <template v-if="galeriaSeleccionada.length > 0">
            <draggable
            :list="galeriaSeleccionada"
            :disabled="!enabled"
            class="row mx-0 px-5 pt-4"
            tag="div"
            ghost-class="ghost"
            @change="checkMove"
            @start="dragging = true"
            @end="dragging = false"
            >
                <div v-for="(data,index) in galeriaSeleccionada" :key="index" class="col-auto position-relative pl-0">
                    <img :src="data.imagen" class="galery-img obj-cover mr-3 mb-3 border" />
                    <div class="position-absolute br-20 d-middle-center bg-white cr-pointer text-general shadow-14" style="height:24px;width:24px;top:7px;right:37px;" @click="eliminarImagen(data.id)">
                        <el-tooltip content="Eliminar" placement="bottom" effect="light">
                            <i class="icon-cancel f-17 cr-pointer" />
                        </el-tooltip>
                    </div>
                </div>
            </draggable>
        </template>
        <template v-else>
            <div class="row mx-0 px-5 pt-4">
                <p class="f-14">No se han agregado imagenes</p>
            </div>
        </template>
    </modal>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"

export default {
    props:{
        tipo:{
            type: Number,
            default: 0,
        },
        galeriaSeleccionada:{
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            cargando: false,
            enabled: true,
            dragging: false,
            imagenes: [],
            imgGalery: '/img/auth/Admin/admin_ventas.png',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalGaleriaVista.toggle();
        },
        async eliminarImagen(id){
            try {
                this.cargando = true;
                const {data} = await Service.deleteImagen(id);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.actualizarOrden(id);
            } catch(error){
                this.error_catch(error)
            } finally{
                this.cargando = false;
            }
        },

        async actualizarOrden(id){
            let index = this.galeriaSeleccionada.findIndex((item) => item.id === id);
            if(index != -1){
                this.galeriaSeleccionada.splice(index,1);
                this.galeriaSeleccionada.map(function(item, index){
                    item.posicion = index + 1;
                });
            }
        },

        checkMove: function(e){
            this.updateOrden(e);
        },

        async updateOrden(e){
            try {
                const model = {
                    idItemCambio: e.moved.element.id,
                    tipo: e.moved.element.tipo,
                    newPosicion: e.moved.newIndex + 1,
                    oldPosicion: e.moved.oldIndex + 1,
                };
                const {data} = await Service.updateOrdenImgIngresoRegistro(model);
            } catch(e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.galery-img{
    width: 288px;
    height: 162px;
}
</style>
